import React from 'react'
import { Link } from "react-router-dom";   

function Profile() {
  return (
    <div>
      <h1>Profile</h1>
        <Link to="/Employee">Home</Link>
        <br /> 
    </div>
  )
}

export default Profile
