import React from 'react'
import { Link } from "react-router-dom";   

function ClockIn() {
  return (
    <div>
        <h1>ClockIn</h1>   
        <Link to="/Employee">Home</Link>
        <br />   
    </div>
  )
}

export default ClockIn
