import React from 'react'
import { Link } from "react-router-dom";

function NewsLetters() {
  return (
    <div>
      <h1>NewsLetters</h1>
      <Link to="/Admin">Home</Link>
    </div>
  )
}

export default NewsLetters
