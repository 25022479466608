import React from 'react'
// import PropTypes from 'prop-types'

const NotFound = () => {
  return (
    <div>NotFound</div>
  )
}

// NotFound.propTypes = {}

export default NotFound