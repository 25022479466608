import React from 'react'
import { Link } from "react-router-dom";   

function SKPConsole() {
  return (
    <div>
      <h1>SKPConsole</h1>
      <Link to="/Employee">Home</Link>
      <br /> 
    </div>
  )
}

export default SKPConsole
