import React from 'react'
import { Link } from "react-router-dom";   

function NewsLetter() {
  return (
    <div>
      <h1>NewsLetter</h1>
        <Link to="/Employee">Home</Link>
        <br /> 
    </div>
  )
}

export default NewsLetter
