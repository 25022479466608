// src/App.js
import React from 'react';
import VideoChat from './VideoChat';

function Meets() {
  return (
    <div className="Meets">
      <VideoChat />
    </div>
  );
}

export default Meets;